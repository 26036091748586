/* eslint-disable no-undef */
import React from "react"
import StructurePageMtg from "@components/pageCasosdeExito/template/templatePageMtg"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Cliente de Beex: MTG [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/casos-de-exito/mtg-peru/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content="Averigua qué tan eficiente fueron los marcadores predictivos de Beex y su suite CRM en las campañas de cobranza y ventas de MTG." />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cliente de Beex: MTG" />
      <meta
        property="og:url"
        content={`https://beexcc.com/casos-de-exito/mtg-peru/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta property="og:description" content="Averigua qué tan eficiente fueron los marcadores predictivos de Beex y su suite CRM en las campañas de cobranza y ventas de MTG." />
    </Helmet>
    <StructurePageMtg location={location} />
  </div>
)

export default IndexPage
